<template>
  <div class="footer">
    <h6>made by <a href="https://penueling.com">再不寫就要忘了</a></h6>
  </div>
</template>
<script>
export default {
  name: "Footer"
};
</script>
